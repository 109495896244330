<template>
  <Drawer title="套餐选择" :mask-closable="false" v-model="show" width="1280" inner class="chooseweixin">
    <Form ref="searchForm" :model="searchForm" inline :label-width="70" @submit.native.prevent>

      <FormItem label="姓名" prop="personName">
        <Input type="text" :maxlength=50 v-model="searchForm.personName" placeholder="请输入姓名" v-width="120"/>
      </FormItem>
      <FormItem label="手机号码" prop="mobile">
        <Input type="text" :maxlength=50 v-model="searchForm.mobile" placeholder="请输入手机号码" v-width="120"/>
      </FormItem>
      <FormItem label="状态" prop="status">
        <Select v-model="searchForm.status" placeholder="状态">
          <Option value="1">已登记</Option>
          <Option value="0">未登记</Option>
        </Select>
      </FormItem>
      <FormItem label="订单时间" prop="startTime">
        <DatePicker type="date" transfer @on-change="datePickerStartTime" v-model="searchForm.startTime"
                    placeholder="开始时间" v-width="120"/>
        ~&nbsp;
        <DatePicker type="date" transfer @on-change="datePickerEndTime" v-model="searchForm.endTime"
                    placeholder="结束时间" v-width="120"/>
      </FormItem>
      <Form-item style="margin-left:-35px" class="br">
        <Button @click="handleSearch" type="primary" icon="ios-search">搜索</Button>
        <Button @click="handleReset">重置</Button>
      </Form-item>
    </Form>
    <!--            <Alert show-icon>-->
    <!--                已选择-->
    <!--                <span class="select-count">{{ itemSelectedData.length }}</span> 项-->
    <!--                <a class="select-clear" @click="clearSelectAll">清空</a>-->
    <!--            </Alert>-->
    <!--项目列表-->
    <!--            <Table-->
    <!--                    :max-height="tableMaxHeight"-->
    <!--                    row-key="id"-->
    <!--                    :loading="comboLoading"-->
    <!--                    border-->
    <!--                    :columns="columns"-->
    <!--                    sortable="custom"-->
    <!--                    :data="comboData"-->
    <!--                    ref="table"-->
    <!--                    @on-selection-change="handleSelectAll"-->
    <!--                    @on-select-cancel="handleCancel"-->
    <!--                    @on-select="handleSelect"-->
    <!--                    @on-select-all-cancel="handleCancelSelectAll"-->
    <!--            ></Table>-->
    <!--项目列表-->
    <div style="position: relative;height: calc(100vh - 260px);overflow-y:auto;overflow-x:hidden;">
      <vxe-table
          v-if="show"
          border
          resizable
          ref="xTree"
          row-id="id"
          :radio-config="{ checkMethod: checkMethod, highlight:true,trigger:'row'}"
          :data="data"
          @radio-change="radioChange"
      >
        <vxe-column type="radio" align="center" title="选择" v-if="selectType == 'radio'" width="60"/>
        <vxe-column field="realName" title="姓名" width="80"/>
        <vxe-column field="userPhone" title="手机号" width="110"/>
        <vxe-column field="gender" title="性别" width="60"/>
        <vxe-column field="marriage" title="婚姻" width="60"/>
        <vxe-column field="cardId" title="身份证号" width="160"/>
        <!--        <vxe-column field="comboId" title="套餐ID" width="300"/>-->
        <vxe-column field="productName" title="套餐名称" width="300"/>
        <!--                <vxe-column field="simpleSpell" title="套餐简拼" align="center"/>-->
        <!--        <vxe-column field="orderId" title="订单编号" align="center"/>-->
        <vxe-column field="createTime" title="下单时间" align="center"/>
        <vxe-column field="payTime" title="支付时间" align="center" width="160"/>
      </vxe-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <!--分页-->
    <!--分页-->
    <Row type="flex" justify="end" class="page">
      <Page :current="searchForm.pageNumber" :total="total"
            :page-size="searchForm.pageSize"
            @on-change="changePage" @on-page-size-change="changePageSize"
            size="small" show-total></Page>
    </Row>
    <div class="demo-drawer-footer">
      <Button style="margin-right: 8px" @click="show = false">关闭</Button>
      <Button type="primary" @click="submitClick">确定</Button>
    </div>
  </Drawer>
</template>

<script>
import {getOrderList} from '@/api/healthy/weixin'
import uniqBy from "lodash.uniqby";
import remove from "lodash.remove";
import differenceBy from "lodash.differenceby";
import {getMonthString} from "../../../api/tools/tool";
import {formatDate} from "@/api/tool.js";

export default {
  name: "weixinDrawer",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ""
    },
    selectType: {
      type: String,
      default: "radio"
    },
    comboId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tableMaxHeight: 0,
      show: this.value,
      comboData: [],
      comboLoading: false,
      total: 0,
      loading: false,
      data: [],
      searchForm: {
        // 搜索框对应data对象
        pageNumber: 1, // 当前页数
        pageSize: 20, // 页面大小
        startTime: null,//开始时间
        endTime: null,//结束时间
        sort: 'order_num', // 默认排序字段
        order: 'asc', // 默认排序方式
        personName: '',
        mobile: '',
        status: '0'
      }
    }
  },
  mounted(){
    //this.date = "当日";
    let date  = getMonthString(new Date());
    if (!this.$hospitalName.queryTimeChange){
      if(date!=null && date.length == 2){
        this.searchForm.startTime = date[0]+ " 00:00:00";
        this.searchForm.endTime = date[1]+ " 23:59:59";
        this.getDataList();
      }
    }else {
      this.pick("当日");
    }
  },
  methods: {
    pick(e) {
      //this.personList = [];
      //this.inspectionInfoList = [];
      //this.personParam.pageNumber = 1;
      if (e == '自定义') {
        this.handleReset()
        this.datePickerShow = true;
      } else if (e == '当日') {
        this.handleReset()
        this.searchForm.startTime = formartDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.searchForm.endTime = null;
        this.getDataList()
      } else if (e == '当月') {
        this.handleReset()
        this.searchForm.startTime = formartDate(new Date(), 'yyyy-MM-dd  HH:mm:ss');
        this.searchForm.endTime = null;
        this.getDataList()
      } else if (e == "重置") {
        this.resetSearchForm();
      }
    },
    //页码
    changePage(v) {
      this.searchForm.pageNumber = v;
      this.getDataList();
    },
    //每页数据
    changePageSize(v) {
      this.searchForm.pageSize = v;
      this.getDataList();
    },
    //套餐查询
    inputSearchChange() {
      this.searchForm.pageNumber = 1;
      this.getDataList();
    },
    handleSearch() {
      this.getDataList();
    },
    //重置
    handleReset() {
      this.searchForm.pageNumber = 1;
      let _this = this;
      setTimeout(function () {
        _this.date = "当日";
        console.log("重置")
        if (!_this.$hospitalName.queryTimeChange){
          let date  = getMonthString(new Date());
          console.log(date)
          if(date!=null && date.length == 2){
            _this.searchForm.startTime = date[0]+ " 00:00:00";
            _this.searchForm.endTime = date[1]+ " 23:59:59";
          }
        }
        else{
          _this.searchForm.startTime = formartDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
          _this.searchForm.endTime = null;
        }
        //_this.inputSearchChange("");
      },100);

      this.searchForm.name = "";
      // 重新加载数据
      this.getDataList();
    },
    //确认选中
    submitClick() {
      this.$emit("selCallBack", this.itemSelectedData);
      this.closeModal(false);
    },
    datePickerEndTime(e) {
      if (e) {
        let endTime = new Date(e);
        endTime.setHours(23, 59, 59, 999);
        endTime = endTime.setDate(endTime.getDate());
        this.searchForm.endTime = formatDate(new Date(endTime), 'yyyy-MM-dd HH:mm:ss');
      }
    },
    //人员日期查询
    datePickerStartTime(e) {
      if (e) {
        let searctTime = new Date(e);
        searctTime.setHours(0, 0, 0, 0);
        searctTime = searctTime.setDate(searctTime.getDate());
        console.log(searctTime)
        this.searchForm.startTime = formatDate(new Date(searctTime), 'yyyy-MM-dd HH:mm:ss');

        console.log(this.searchForm.startTime)
      }
    },
    //单选选中
    radioChange(e) {//e.row obj
      if (this.selectType == "radio") {
        this.itemSelectedData = [e.row];
      }
    },
    //是否可以选中选择框
    checkMethod({row}) {
      if (row.children && row.children.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    //分页查询
    getDataList() {

      // let strStartTime = toDateString(
      //     this.searchForm.startTime.value()?.$d,
      //     'yyyy-MM-dd HH:mm:ss',
      // )
      // console.log(strStartTime)
      //
      // let strEndTime = toDateString(
      //     this.searchForm.endTime?.$d,
      //     'yyyy-MM-dd HH:mm:ss',
      // )
      // console.log(strEndTime)
      //
      //
      // this.loading = true;
      console.log(this.searchForm)
      //
      // this.searchForm.startTime = strStartTime;
      // this.searchForm.endTime = strEndTime

      getOrderList(this.searchForm).then(res => {
        if (res.success) {
          this.data = res.data.list
          this.data.orderId = res.data.orderId
          this.data.orderTime = res.data.creatTime
          this.data.payTime = res.data.payTime
          this.total = res.data.total;

          // if (this.itemSelectedData && this.itemSelectedData.length > 0 || this.itemSelectedData[0].id) {
          //     if (this.selectType == 'radio') {
          //         let findData1 = this.findData(this.data, this.itemSelectedData[0].id);
          //         this.$refs.xTree.setRadioRow(findData1)
          //     }
          // }
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    closeModal(val) {
      this.$emit('input', val);
    },
    //清空项目选择
    clearSelectAll() {
      this.itemSelectedData = [];
      this.$refs.table.selectAll(false);
    },
    //选择素有套餐项
    handleSelectAll(selection) {
      if (selection.length > 0) {
        selection.forEach(row => {
          if (row.children && row.children.length > 0) {
            row.children = null;
            row.projectList = null;
          }
        })
      }
      //监听全选，有可能用户先单独选择了某几项，被我们push进去，然后再点了全选，因此数组合并需要去重一下
      this.itemSelectedData = uniqBy(this.itemSelectedData.concat(selection), "id");
    },
    //取消选择套餐项
    handleCancel(selection, row) {
      //监听取消选中某一项，从已选项中删除取消项，row代表取消选择的项数据
      remove(this.itemSelectedData, n => {
        return n.id === row.id;
      });
    },
    //选择一项套餐项
    handleSelect(selection, row) {
      if (row.children && row.children.length > 0) {
        row.children = null;
        row.projectList = null;
      }
      //监听选中某一项，添加到已选项
      this.itemSelectedData.push(row);
    },
    //取消选择所有套餐项
    handleCancelSelectAll(selection) {
      //监听取消全选，从已选项中移除当页数据
      this.itemSelectedData = differenceBy(this.itemSelectedData, this.itemData, "id");
    },
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      if (val) {
        window.onresize = () => {
          // 计算高度
          let height = document.documentElement.clientHeight;
          this.tableMaxHeight = height - 300;
        };
        // 计算高度
        let height = document.documentElement.clientHeight;
        this.tableMaxHeight = height - 300;
        //this.searchForm.type = this.physicalType;
        // this.itemSelectedData = JSON.parse(JSON.stringify(this.checkedList));

        // if (this.physicalType != '健康体检') {
        //     getDictDataByType("workState").then(res => {
        //         if (res.success) {
        //             this.careerStageArr = res.data;
        //             this.getDataList();
        //         }
        //     });
        // } else {
        //this.searchForm.careerStage = "";
        this.getDataList();
        //}
      } else {
        this.closeModal(val);
      }
    }
  }
}
</script>

<style lang="less">
.chooseweixin {
  .radio-group {
    width: 100%;
    overflow: hidden;

    .row-border {
      padding-top: 15px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d0d0d0;

      .radio-group-radio {
        padding-left: 10px !important;

        .ivu-radio-group-item {
          font-size: 0 !important;
        }
      }

      .radio-group-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }

  .ivu-scroll-container {
    height: calc(100vh - 300px) !important;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    .ivu-scroll-loader {
      position: relative;
      bottom: -30px;
    }
  }

  .search-input {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .vxe-table--render-default .vxe-body--column:not(.col--ellipsis), .vxe-table--render-default .vxe-footer--column:not(.col--ellipsis), .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
    padding: 6px 0;
  }
}
</style>
